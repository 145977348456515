import '../styles/styles.scss'
import useTelemetry from '../utils/hooks/useTelemetry'

export function checkProduction (props) {
  // Check if in production

  const isProduction = process.env.NODE_ENV === 'production'

  return {
    ...initialProps,
    isProduction
  }
}

function MyApp ({ Component, pageProps }) {
  useTelemetry()
  return <Component {...pageProps} />
}

export default MyApp
